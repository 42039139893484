body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root{
  --blue-color:#002087;
  --lighterblue-color:#312ECB;
  --lightgrey-color:#828282;
  --grey-color:#595A5B;
  --white-color:white;
  --lightblue-color:#8AB4F8;
  --red-color:red;
  --black-color:black;
}
