.form{
    width: 70%;
    height: 45%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.form1{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}
.head1{
    font-size: 27px;
    font-weight: bold;
    color: var(--blue-color);
}
.head2{
    font-size: 15px;
}
.head2 > a{
    color: var(--blue-color);
    text-decoration: none;
}
.form2{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 100%;
}
.form2 > div{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.forminp{
    height: 39px;
    border: 1px solid var(--lightgrey-color);
    outline: none;
    padding-left: 10px;
    border-radius: 5px;
}
.reform{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.reform > div{
    display: flex;
    position: relative;
    align-items: center;
    max-width: 100%;
}
.reform > div > input{
    width: 98%;
}
.reform > div > svg{
    margin-left: -30px;
    width: 20px;
    height: 20px;
    color: var(--grey-color);
    cursor: pointer;
}
.reform > a{
    text-decoration: none;
    color: var(--blue-color);
    font-size: 15px;
    cursor: pointer;
}
.form3 > button{
    background-color: var(--blue-color);
    color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 17px;
}
.or {
    display: flex;
    flex-direction: row;
    color: var(--grey-color);
    font-size: 15px;
}
.or:before, .or:after{
    content: "";
    flex: 1 1;
    border-bottom: 0.5px solid;
    margin: auto;
}
.or:before {
    margin-right: 10px
}
.or:after {
    margin-left: 10px
}
.form3 > a{
    text-decoration: none;
    position: relative;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--blue-color);
    gap: 10px;
}
.form3 > a::before{
    position: absolute;
    border-radius: 10px;
    border: 1px solid transparent; 
    background: linear-gradient(114.46deg, var(--lightblue-color) 43.11%, var(--blue-color) 100%) border-box;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor; 
    mask-composite: exclude;
    content: '';
    inset: 0;
}
.form3 > a > svg{
    height: 20px;
    width: 20px;
}
@media only screen and (max-width:700px){.form{width: 90%;height: 70%;}}