.maindiv1{
    display: flex;
    height: 100vh;
    width: 100%;
    background-color: var(--white-color);
    box-sizing: border-box;
}
.currentPage1{
    width: 53.5%;
    min-height: auto;
    max-height: 150vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 46.5%;
}
@media only screen and (max-width:980px){
    .currentPage1{
        width: 100%;
        margin-left: 0%;
        align-items: flex-start;
    }
}