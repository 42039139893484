.parent{
    position: fixed;
    z-index: 100000000;
    background-color: rgba(0,0,0,0.4);
    height: 100vh; width: 100%;
    top:0px; left: 0px;
    display: none;
    justify-content: center;
    align-items: center;
}
.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #FF2539;
    border-right: 16px solid #8AB4F8;
    border-bottom: 16px solid #002087;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin .5s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }