@import url("./constant.css");
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */   
    z-index: 10000; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: var(--dark-mode-color) ;/* Fallback color */
    background-color: var(--light-dark) /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal_content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
  }
  
  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  
  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  
  /* The Close Button */
  .close {
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: var(--theme);
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal_header {
    padding: 2px 16px;
    text-align: center;
  }
  
  .modal_body {padding: 2px 16px; text-align: center;}
  .modal_footer{
    width:100%;
    padding: 10px 16px;
  }
  .modal_footer > * {
    width:100%;
    display: flex;
    justify-content: space-between;
  }

  .modal_footer > * > button{
    padding:10px 25px;
  }

  @media  only screen and (max-width:767px) {
    .modal_content {
       width:70%;
    }
  }

  @media  only screen and (max-width:550px) {
    .modal_content {
       width:90%;
    }
  }
  
  