.forpass{
    width: 70%;
    height: 45%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.for1, .for2{
    display: flex;
    flex-direction: column;
}
.for1{
    gap: 5px;
}
.for1 > .pass1{
    font-size: 27px;
    color: var(--blue-color);
    font-weight: bold;
}
.for1 > span{
    font-size: 15px;
}
.for2{
    gap: 25px;
}
.for2 > input{
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    border: 1px solid var(--lightgrey-color);
    max-width: 100%;
    padding-left: 10px;
}
.for2 > button{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    background-color: var(--blue-color);
    height: 35px;
    border-radius: 5px;
    border: none;
    font-size: 17px;
    font-weight: 500;
}
.for2 > a{
    text-decoration: none;
    border: 2px solid var(--blue-color);
    color: var(--blue-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
}