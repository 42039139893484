.mainSideBar{
    background: url(../images/Background.jpg);
    width: 46.5%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    position: fixed;
    /* padding: 10px; */
    display: flex;
    flex-direction: column;
    gap: 50%;
}
.top{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    position: relative;
    margin-left: 50px;
    margin-top: 10px;
}
.top > img{
    height: 90px;
}
.subcontent{
    color: var(--white-color);
    font-size: 23px;
    font-weight: 600;
    margin-left: -50px;
    margin-top: 10px;
}
.middle{
    position: absolute;
    right: 0px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-size: 15px;
    top: 30%;
    font-weight: 500;
}
.middle > a{
    height: 90px;
    width: 90px;
    border-radius: 10px 0 0 10px;
    background-color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: right;
    cursor: pointer;
    text-decoration: none;
    cursor: pointer;;
}
.mid1{
    color: var(--blue-color);
}
.mid2{
    color: var(--lightgrey-color);
}
.bottom{
    margin-left: 50px;
    color: var(--white-color);
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 70%;
}
.bot1{
    font-size: 40px;
    font-weight: 600;
}
.bot2{
    font-size: 20px;
}
@media only screen and (max-width:980px){
    .mainSideBar{
        display: none;
    }
}