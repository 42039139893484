.non{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
}
.non > a{
    height: 40px;
    width: 200px;
    font-size: 17px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    background-color: var(--blue-color);
    border-radius: 10px;
    text-decoration: none;
}

.non > img{
    height: 300px;
    width: 40%;
}

.non > span{
    font-size: 40px;
    font-weight: 700;
    text-align: center;
}
@media only screen and (max-width:900px){
    .non > img{
        width: 70%;
    }
}
@media only screen and (max-width:500px){
    .non > img{
        width: 100%;
        height: 200px;
    }
}