.top{
    display: none;
    align-items: center;
    justify-content: left;
    width: 200px;
    align-self: flex-start;
    /* margin-left: 20px; */
    flex-direction: row;
    /* position: absolute; */
    /* left: 0; */
    /* top: 0; */
    margin-top: 10px;
}
.top > img{
    height: 70px;
}
.subcontent{
    color: var(--blue-color);
    font-size: 20px;
    font-weight: 600;
    margin-left: -50px;
    margin-top: 10px;
}
@media only screen and (max-width:980px){.top{display: flex;}}