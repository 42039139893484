.reset{
    width: 70%;
    height: 45%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}
.reset > img{
    height: 150px;
    width: 150px;
}
.reset > span{
    font-weight: bold;
    font-size: 19px;
}
.reset > a{
    background-color: var(--lighterblue-color);
    color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    /* font-size: 20px; */
    text-decoration: none;
    width: 70%;
    font-weight: 500;
    padding: 5px;
}
@media only screen and (max-width:700px){.reset{width: 90%;}}