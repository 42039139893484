.reLogin{
    width: 67%;
    height: 60%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.relink1{
    display: flex;
    flex-direction: column;
    gap: 10px;    
}
.re1{
    color: var(--blue-color);
    font-size: 35px;
    font-weight: bold;
}
.relink > a > .re2{
    font-size: 25px;
    font-weight: 700;
}
.reLogin >.relink{
    gap: 35px;
    display: flex;
    flex-direction: column;
}
.relink > a{
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 10px;
    text-decoration: none;
    max-width: 100%;
    padding: 12px 20px ;
    min-height: 100px;
    max-height: 130px;
    color: var(--black-color);
    font-size: 14px;
}
.relink > a::before{
    position: absolute;
    border-radius: 20px;
    border: 1px solid transparent; 
    background: linear-gradient(114.46deg, var(--lightblue-color) 43.11%, var(--blue-color) 100%) border-box;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor; 
    mask-composite: exclude;
    content: '';
    inset: 0;
}
@media only screen and (max-width:770px){.reLogin{width: 90%;height: 70%;}}