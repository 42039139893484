form{
    width: 70%;
    height: 70%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding-bottom: 100px;
}
.formheader{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.head1{
    color: var(--blue-color);
    font-size: 32px;
    font-weight: bold;
}
.head2 > a{
    text-decoration: none;
    color: var(--blue-color);
}
.mainform{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.form1{
    display: flex;
    gap: 10px;
    width: 100%;
    padding: 0;
}
.form1 > input{
    width: 50%;
    outline: none;
    height: 35px;
    padding-left: 15px;
    border:1px solid var(--lightgrey-color);
    border-radius: 5px;
    appearance: none;
}
.form2{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding-left: 0px;
}
.form2 >input{
    outline: none;
    height: 35px;
    padding-left: 15px;
    border:1px solid var(--lightgrey-color);
    border-radius: 5px;
    appearance: none;
    width: 96%;
    margin-left: -12px;
}
.form2 > svg{
    height: 20px;
    width: 20px;
    color: var(--grey-color);
    margin-left: -30px;            
}
.form3{
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 3px;
    flex-wrap: wrap;
}
.form3 > a{
    text-decoration: none;
    color: var(--blue-color);
}
.form4{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.form4 > button{
    height: 40px;
    width: 100;
    color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blue-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 17px;
}
.or {
    display: flex;
    flex-direction: row;
    color: var(--grey-color);
    font-size: 15px;
}
.or:before, .or:after{
    content: "";
    flex: 1 1;
    border-bottom: 0.5px solid;
    margin: auto;
}
.or:before {
    margin-right: 10px
}
.or:after {
    margin-left: 10px
}
.form4 > a{
    text-decoration: none;
    position: relative;
    width: 99%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--blue-color);
    gap: 10px;
}
.form4 > a::before{
    position: absolute;
    border-radius: 10px;
    border: 1px solid transparent; 
    background: linear-gradient(114.46deg, var(--lightblue-color) 43.11%, var(--blue-color) 100%) border-box;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor; 
    mask-composite: exclude;
    content: '';
    inset: 0;
}
.form4 > a > svg{
    height: 20px;
    width: 20px;
}
.result1{
    color: var(--red-color);
    align-self: flex-start;
    font-size: 15px;
}
@media only screen and (max-width:980px){
    .form2 > input{
        width: 96.5%;
    }
    form{
        gap: 30px;
        height: 100%;
        align-self: flex-start;
    }
}
@media only screen and (max-width:660px){
    form{
        width: 90%;
        /* height: 110vh; */
        /* gap: 50px; */
        
        /* overflow: hidden; */
        /* align-self: center; */
        /* margin-top: 30px; */
    }
    .form1{
        flex-direction: column;
        /* max-width: 100%; */
    }
    .form1 > input{
        width: 96.5%;
    }
    .form2 > input{
        width: 96%;
        margin-left: -6px;
    }
}
@media only screen and (max-width:610px){
    .form1 > input{
        width: 95%;
    }
    .form2 > input{
        width: 94%;
        /* margin-left: -9px; */
    }
}
@media only screen and (max-width:450px){
    .form1 > input{
        width: 94%;
    }
    .form2 > input{
        width: 93.5%;
        margin-left: -9px;
    }
    form{
        /* height: 120vh; */
    }
}