.veri{
    display: flex;
    flex-direction: column;
    gap: 50px;
    /*height: 35%; */   
    width: 70%;
}

.veri1{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.verisp1{
    font-size: 35px;
    font-weight: bold;
    color: var(--blue-color);
}
.veri1 > a,.veri1 > button{
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blue-color);
    color: var(--white-color);
    text-decoration: none;
    border-radius: 5px;    
    cursor: pointer;    
    margin-top: 20px;
}
.veri1 > .veria{
    text-decoration: none;
    border: 2px solid var(--blue-color);
    color: var(--blue-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    background-color: var(--white-color);
}
.veriem{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
.veriem > div{
    display: flex;
    align-items: center;
}
.veriem > div > input{
    width: 97%;
    height: 35px;
    padding-left: 10px;
    border: 1px solid var(--lightgrey-color);
    border-radius: 5px;
    outline: none;
}
.veriem > div > svg{
    margin-left: -30px;
    width: 20px;
    height: 20px;
    color: var(--grey-color);
    cursor: pointer;
}
@media only screen and (max-width:700px){.veri{width: 90%;}}